import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Avatar,
  Button,
  Modal,
  Snackbar,
  Divider,
  Switch,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import LoadingData from "../../components/Loaders/Loading";
import { currencyFormats } from "../../constants/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoinsAndBalances } from "../../redux/actions/coinsActions";

const Coins = () => {
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();

  const cachedCoinsWithBalances = useSelector(
    (state) => state.coins.cachedCoinsWithBalances
  );
  const loadingCoins = useSelector((state) => state.coins.loadingCoins);
  const error = useSelector((state) => state.coins.error);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    coinId: null,
    newStatus: null,
  });

  const { vertical, horizontal, open } = snackbarState;
  const theme = useTheme();

  // Dispatch the action to fetch coins and balances when the component mounts
  useEffect(() => {
    if (!cachedCoinsWithBalances || !cachedCoinsWithBalances.length) {
      console.log("Dispatching fetchCoinsAndBalances action"); // Add a log here

      dispatch(fetchCoinsAndBalances(currentUser.token)); // Pass the token to the action
    }
  }, [dispatch, currentUser.token, cachedCoinsWithBalances]);

  if (loadingCoins) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // useEffect(() => {
  //   fetchCoinsAndBalances();
  // }, [fetchCoinsAndBalances]);

  const handleEdit = async (coinId) => {
    try {
      const fetchedCoin = await apiServiceFetchers.getCoinById(
        coinId,
        currentUser.token
      );
      setSelectedCoin(fetchedCoin);
      setEditedFields({
        coin_id: fetchedCoin.coin_id,
        coin_name: fetchedCoin.coin_name,
        price: fetchedCoin.price,
        description: fetchedCoin.description,
        withdrawalCommission: fetchedCoin.withdrawal_commission,
        minimumWithdrawalAmount: fetchedCoin.minimum_withdrawal_amount,
        adminApprovalAmount: fetchedCoin.admin_approval_amount,
        oneOffWithdrawalLimit: fetchedCoin.oneOffWithdrawalLimit,
        dailyWithdrawalLimit: fetchedCoin.dailyWithdrawalLimit,
        minimumTransferAmount: fetchedCoin.minimum_transfer_amount,
        transactionFee: fetchedCoin.transactionFee,
        is_active: fetchedCoin.is_active,
      });
      setOpenEditModal(true);
    } catch (error) {
      throw error;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      // Make the API call to update the coin
      const response = await apiServiceUpdate.updateCoin(
        selectedCoin.id,
        editedFields,
        currentUser.token
      );
      // Check if the update was successful
      if (response.status === 200) {
        // Update the state directly
        fetchCoinsAndBalances();
        // Close the edit modal and clear edited fields
        setOpenEditModal(false);
        setEditedFields({});

        // Show Snackbar notification for successful update
        setSnackbarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: "Coin updated successfully",
          backgroundColor: theme.palette.alert.success,
        });
      } else {
        throw new Error("Failed to update coin");
      }
    } catch (error) {
      setSnackbarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Failed to update coin",
        backgroundColor: theme.palette.alert.warning,
      });
      throw error;
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleStatusToggle = (coinId, newStatus) => {
    setConfirmDialog({
      open: true,
      coinId: coinId,
      newStatus: newStatus,
    });
  };

  const handleCancelToggle = () => {
    setConfirmDialog({
      open: false,
      coinId: null,
      newStatus: null,
    });
  };

  // To handle toggle
  const handleConfirmToggle = async () => {
    const { coinId, newStatus } = confirmDialog;
    try {
      await apiServiceUpdate.updateCoinStatus(
        coinId,
        { isActive: newStatus },
        currentUser.token
      );
      // Fetch coins and balances again to ensure the state is up-to-date
      fetchCoinsAndBalances();

      setSnackbarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Coin status updated!",
        backgroundColor: theme.palette.alert.success,
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Failed to update coin status",
        backgroundColor: theme.palette.alert.warning,
      });
    }

    // Close the confirmation dialog
    setConfirmDialog({
      open: false,
      coinId: null,
      newStatus: null,
    });
  };

  // Helper function to format price with currency
  const formatPriceWithCurrency = (price, currency) => {
    const format = currencyFormats[currency];
    return format ? format(price) : "N/A";
  };
  const labelText =
    editedFields.coin_id === "KES" ? "Withdrawal Fee" : "Withdrawal Fee (%)";

  return (
    <ThemeProvider theme={theme}>
      <Box p={3} sx={{ backgroundColor: theme.palette.background.paper }}>
        <Typography
          sx={{
            padding: 1,
            fontWeight: "bold",
            fontSize: theme.typography.responsiveFontSizes,
          }}
          gutterBottom
        >
          Coins
        </Typography>
        <Divider />
        {loadingCoins ? (
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <LoadingData />
          </Grid>
        ) : cachedCoinsWithBalances === null ||
          cachedCoinsWithBalances.length === 0 ? (
          <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
            No Coins found
          </Typography>
        ) : (
          <Grid container spacing={3} xs={12} mt={1}>
            {cachedCoinsWithBalances.map((coin) => (
              <Grid item xs={12} lg={4} key={coin.id}>
                <Card
                  sx={{
                    border: "1px solid",
                    backgroundColor: theme.palette.background.paper,
                    borderColor: theme.palette.background.paper,
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Avatar
                          src={`${coin.icon_style}`}
                          alt={coin.coin_name}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{
                            fontWeight: 700,
                            fontSize: theme.typography.responsiveFontSizes,
                          }}
                        >
                          {coin.coin_name} ({coin.coin_id})
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{
                            fontWeight: 700,
                            fontSize: theme.typography.responsiveFontSizes,
                          }}
                        >
                          {coin.description}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Typography
                      sx={{
                        mt: 2,
                        fontSize: theme.typography.responsiveFontSizes,
                      }}
                    >
                      Price:{" "}
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.price
                          ? formatPriceWithCurrency(coin.price, coin.coin_id)
                          : "N/A"}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Balance:{" "}
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.balance}{" "}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Withdrawal Fee:
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.coin_name === "Shilling"
                          ? `${coin.withdrawal_commission}`
                          : `${coin.withdrawal_commission}%`}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Minimum Withdrawal Amount:
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.minimum_withdrawal_amount}{" "}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Minimum Transaction Amount:
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.minimum_transfer_amount}{" "}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      One-off Withdrawal Limit:{" "}
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.oneOffWithdrawalLimit}{" "}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Admin approval amount:{" "}
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.admin_approval_amount}{" "}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Daily Withdrawal Limit:{" "}
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.dailyWithdrawalLimit}{" "}
                      </span>
                    </Typography>
                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Transaction Fee:{" "}
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.transactionFee}%{" "}
                      </span>
                    </Typography>

                    <Typography
                      sx={{ fontSize: theme.typography.responsiveFontSizes }}
                    >
                      Status:{" "}
                      <Switch
                        checked={coin.is_active}
                        onChange={() => {
                          handleStatusToggle(coin.id, !coin.is_active); // Toggle status correctly
                        }}
                        color="primary"
                      />
                      <span color="text.secondary" sx={{ fontWeight: 700 }}>
                        {coin.is_active ? "Active" : "Deactivated"}
                      </span>
                    </Typography>

                    <Button
                      variant="contained"
                      onClick={() => handleEdit(coin.id)}
                      sx={{
                        marginTop: 2,
                        width: 100,
                        fontSize: theme.typography.responsiveFontSizes,
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.background.paper,
                        backgroundColor: theme.palette.button.primary,
                        "&:hover": {
                          backgroundColor: theme.palette.button.secondary,
                          borderColor: theme.palette.background.paper,
                          transform: "scale(0.95)",
                        },
                      }}
                    >
                      Edit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {/* modal  used for the edit button */}
        <Modal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: { xs: "80%", lg: "50%" },
              height: { xs: "80%", lg: "auto", md: "60%" },
              position: "absolute",
              overflowY: "auto",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "2px solid",
              borderColor: theme.palette.alert.disabled,
              backgroundColor: theme.palette.background.paper,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Coin
            </Typography>
            <Divider />
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                label="Coin ID"
                name="coin_id"
                value={editedFields.coin_id || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Coin Name"
                name="coin_name"
                value={editedFields.coin_name || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Description"
                name="description"
                value={editedFields.description || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Price"
                name="price"
                type="number"
                value={editedFields.price || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label={labelText}
                name="withdrawalCommission"
                type="number"
                value={editedFields.withdrawalCommission}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Minimum Withdrawal Amount"
                name="minimumWithdrawalAmount"
                type="number"
                value={editedFields.minimumWithdrawalAmount || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Minimum Transaction Amount"
                name="minimumTransferAmount"
                type="number"
                value={editedFields.minimumTransferAmount || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="One-off Withdrawal Limit"
                name="oneOffWithdrawalLimit"
                type="number"
                value={editedFields.oneOffWithdrawalLimit || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Admin approval amount"
                name="adminApprovalAmount"
                type="number"
                value={editedFields.adminApprovalAmount || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Daily Withdrawal Limit"
                name="dailyWithdrawalLimit"
                type="number"
                value={editedFields.dailyWithdrawalLimit || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{
                  fontSize: theme.typography.responsiveFontSizes,
                }}
                label="Transaction Fee (%)"
                name="transactionFee"
                type="number"
                value={editedFields.transactionFee || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Box>
            <Button
              onClick={handleUpdate}
              variant="outlined"
              sx={{
                marginTop: 2,
                fontSize: {
                  xs: "0.875rem",
                  sm: "0.875rem",
                  md: "1rem",
                  lg: "1.125rem",
                },
                width: 100,
                color: theme.palette.text.primary,
                borderColor: theme.palette.background.paper,
                backgroundColor: theme.palette.button.primary,
                "&:hover": {
                  backgroundColor: theme.palette.button.secondary,
                  borderColor: theme.palette.background.paper,
                  transform: "scale(0.95)",
                },
              }}
            >
              Update
            </Button>
            <Button
              onClick={() => setOpenEditModal(false)}
              variant="outlined"
              sx={{
                marginTop: 2,
                fontSize: theme.typography.responsiveFontSizes,
                color: "white",
                borderColor: "none",
                opacity: "0.7",
                backgroundColor: theme.palette.alert.warning,
                "&:hover": {
                  borderColor: theme.palette.text.primary,
                  color: theme.palette.text.primary,
                  transform: "scale(0.95)",
                },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Modal>

        {/* modal  used for the switch button */}
        <Modal
          open={confirmDialog.open}
          onClose={handleCancelToggle}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "80%", lg: "30%" },
              padding: 3,
              backgroundColor: theme.palette.background.paper,
              boxShadow: 24,
              borderRadius: 1,
              textAlign: "center",
            }}
          >
            <Typography id="confirm-dialog-title" variant="h6">
              Confirm Action
            </Typography>
            <Typography id="confirm-dialog-description" sx={{ mt: 2 }}>
              Are you sure you want to{" "}
              {confirmDialog.newStatus ? "activate" : "deactivate"} this coin?
            </Typography>
            <Box
              sx={{ mt: 4, display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="contained"
                onClick={handleConfirmToggle}
                sx={{
                  color: theme.palette.text.primary,
                  borderColor: theme.palette.background.paper,
                  backgroundColor: theme.palette.button.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.button.secondary,
                    borderColor: theme.palette.background.paper,
                    transform: "scale(0.95)",
                  },
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelToggle}
                sx={{
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.alert.warning,
                  "&:hover": {
                    borderColor: theme.palette.text.primary,
                    color: theme.palette.text.primary,
                    transform: "scale(0.95)",
                  },
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarState.message}
          key={`${vertical}${horizontal}`}
          sx={{ backgroundColor: snackbarState.backgroundColor }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Coins;
