import React from "react";
import { Typography, Paper, Card } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Paper elevation={2}>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          GOCHAPAA PRIVACY POLICY
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated May 19, 2022
        </Typography>
        <Typography variant="body1" paragraph>
          Thank you for choosing to be part of our community at GoChapaa
          ("Company," "we," "us," or "our").
        </Typography>
        <Typography variant="body1" paragraph>
          We are committed to protecting your personal information and your
          right to privacy. If you have any questions or concerns about this
          privacy notice or our practices with regard to your personal
          information, please contact us at support@gochapaa.com.
        </Typography>
        <Typography variant="body1" paragraph>
          The purpose of this privacy notice is to explain to you in the
          clearest way possible what information we collect, how we use it, and
          what rights you have in relation to it. If there are any terms in this
          privacy notice that you do not agree with, please discontinue use of
          our services immediately.
        </Typography>
        <Typography variant="body1" paragraph>
          Please read this privacy notice carefully, as it will help you
          understand what we do with the information that we collect.
        </Typography>
        <Typography variant="h6" gutterBottom>
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Personal information you disclose to us.</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: We collect personal information that you provide to us.
        </Typography>
        <Typography variant="body1" paragraph>
          We collect personal information that you voluntarily provide to us
          when you register to express an interest in obtaining information
          about us or our products and Services, when you participate in
          activities (such as by posting messages in our online forums or
          entering competitions, contests or giveaways) or otherwise when you
          contact us.
        </Typography>
        <Typography variant="body1" paragraph>
          The personal information that we collect depends on the context of
          your interactions with us and the choices you make and the products
          and features you use. The personal information we collect may include
          the following:
        </Typography>
        <Typography variant="body1" paragraph>
          Your names, email, phone number and identification.
        </Typography>
        <Typography variant="body1" paragraph>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Information automatically collected</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you use our app or visit our website.
        </Typography>
        <Typography variant="body1" paragraph>
          This information does not reveal your specific identity (like your
          name or contact information) but may include device and usage
          information, such as your IP address, browser and device
          characteristics and ID, operating system, language preferences,
          referring URLs, browsing history, abusing history, device name,
          country, location, information about how and when you use our and
          other technical information. This information is primarily needed to
          maintain the security and operation of our app, and for our internal
          analytics and reporting purposes.
        </Typography>
        <Typography variant="body1" paragraph>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </Typography>
        <Typography variant="h6" gutterBottom>
          2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: We only share information with your consent, to comply with
          laws, to provide you with services, to protect your rights, or to
          fulfill business obligations.
        </Typography>
        <Typography variant="body1" paragraph>
          You hereby acknowledge and agree that we may process or share your
          data that we hold to provide various services, confirm your identity,
          provide notices and information, respond to inquiries and to provide
          any ancillary services.
        </Typography>
        <Typography variant="body1" paragraph>
          More specifically, we may need to process your data or share your
          information in the following situations:
        </Typography>
        Business Transfers. We may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business to
        another company.
        <Typography variant="h6" gutterBottom>
          Business Transfers.
        </Typography>
        <Typography variant="body1" paragraph>
          We may share or transfer your information in connection with, or
          during negotiations of, any merger, sale of company assets, financing,
          or acquisition of all or a portion of our business to another company.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Affiliates.{" "}
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with our affiliates, in which case we
          will require those affiliates to honor this privacy notice. Affiliates
          include our parent company and any subsidiaries, joint venture
          partners or other companies that we control or that are under common
          control with us.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          Service Providers for Consignment of Handling Personal Information.{" "}
        </Typography>
        <Typography variant="body1" paragraph>
          We may consign your personal information to an external contractor to
          perform a number of tasks essential for providing our services.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          Business Partners.{" "}
        </Typography>
        <Typography variant="body1" paragraph>
          We may share your information with our business partners to offer you
          certain products, services or promotions.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          Other Users.{" "}
        </Typography>
        <Typography variant="body1" paragraph>
          When you share personal information or otherwise interact with public
          areas of the App or Website, such personal information may be viewed
          by all users and may be publicly made available outside in perpetuity
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information which is not personally
          identifiable. A cookie is a small-sized text file transferred and
          stored in a user’s device upon accessing a website to ensure efficient
          and safe usage of the web by the user. If the user re-visits the
          website after the cookie has been stored, the website recognizes the
          device of the user and reloads the previous settings and usage
          history. Also, it allows the website to analyze the information
          generated in using the service, information of visited services, time
          and frequency of the access, etc. The cookie does not automatically
          and actively collect personal identification information, and the user
          has the option to refuse the storage of a cookie or delete it at any
          time. By adjusting the settings in the web browser, the user can allow
          all cookies, be alerted every time a cookie is stored, or reject all
          cookies. However, if the user opts out from the use of a cookie or
          restricts its function, it may prevent the user from accessing
          convenient functions of the website, thereby limiting the overall user
          experience. We may use cookies and similar tracking technologies (like
          web beacons and pixels) to access or store information.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: you choose to register or log in to our services using a
          social media account, we may have access to certain information about
          you.
        </Typography>
        <Typography variant="body1" paragraph>
          Our offers you the ability to register and login using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          profile picture as well as other information you choose to make public
          on such social media platforms.
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant webpage or our app. Please note that we do not
          control, and are not responsible for, other uses of your personal
          information by your third-party social media provider. We recommend
          that you review their privacy notice to understand how they collect,
          use and share your personal information, and how you can set your
          privacy preferences on their sites and apps.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: We may transfer, store, and process your information in
          countries other than your own. Our servers are located in various
          countries. If you are accessing our App from outside, please be aware
          that your information may be transferred to, stored, and processed by
          us in our facilities and by those third parties with whom we may share
          your personal information (see 'WILL YOUR INFORMATION BE SHARED WITH
          ANYONE?" above), in and other countries.
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          If you are a resident in the European Economic Area (EEA) or United
          Kingdom then these countries may not necessarily have data protection
          laws or other similar laws as comprehensive as those in your country.
          We will however take all necessary measures to protect your personal
          information in accordance with this privacy notice and applicable law.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law(such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than
          necessary.{" "}
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.{" "}
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          7. DO WE COLLECT INFORMATION FROM MINORS?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age. We do not knowingly solicit data from or market
          to children under 18 years of age. By using the App, you represent
          that you are at least 18 or that you are the parent or guardian of
          such a minor and consent to such minor dependent's use of the App. If
          we learn that personal information from users less than 18 years of
          age has been collected, we will deactivate the account and take
          reasonable measures to promptly delete such data from our records. If
          you become aware of any data we may have collected from children under
          age 18, please contact us.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: You may review, change, or terminate your account at any
          time. If you are a resident in the EEA or UK and you believe we are
          unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          Account Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our file store prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with applicable legal requirements.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          Opting out of email marketing
        </Typography>
        <Typography variant="body1" paragraph>
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by
          contacting us. You will then be removed from the marketing email list
          — however, we may still communicate with you, for example to send you
          service-related emails that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes.
        </Typography>
        <Typography variant="h6" gutterBottom>
          9. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>
        <Typography variant="body1" paragraph>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible.
        </Typography>{" "}
        <Typography variant="body1" paragraph>
          If we make material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by directly
          sending you a notification. We encourage you to review this privacy
          notice frequently to be informed of how we are protecting your
          information.
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>
        <Typography variant="body1" paragraph>
          It you have questions or comments about this notice, you may email us
          at support@gochapaa.com
        </Typography>{" "}
        <Typography variant="h6" gutterBottom>
          11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </Typography>
        <Typography variant="body1" paragraph>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          submit a request form by emailing us at support@gochapaa.com
        </Typography>
      </Card>
    </Paper>
  );
};

export default PrivacyPolicy;
