import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import { coinsReducer } from "./reducer/coinsReducer";
import { faqsReducer } from "./reducer/faqsReducer";
import { notificationReducer } from "./reducer/notificationReducer";

// Combine reducers (in case you have more than one)
const rootReducer = combineReducers({
  coins: coinsReducer,
  faqs: faqsReducer,
  notifications: notificationReducer,
});

// Create the store and apply middleware
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
