import React, { useState, useEffect, useContext } from "react";
import { useTheme, ThemeProvider, Card, Box, Typography } from "@mui/material";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import { AuthContext } from "../../Auth/AuthContext";
import { SkeletonShowBalanceCards } from "../Loaders/Skeleton";

const BalanceWidget = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoinsAndBalances = async () => {
      try {
        // Fetching the list of coins
        const fetchedCoins = await apiServiceFetchers.getAllCoins(
          currentUser.token
        );

        const coinIds = fetchedCoins.coins.map((coin) => coin.coin_id);

        const balances = {};

        for (const coinId of coinIds) {
          const response = await apiServiceFetchers.getTotalBalance(
            currentUser.token,
            coinId
          );
          balances[coinId] = response.balance;
        }

        // Merging coins with their respective balances
        const coinsWithBalances = fetchedCoins.coins.map((coin) => ({
          ...coin,
          balance: balances[coin.coin_id] || 0,
        }));

        setCoins(coinsWithBalances);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCoinsAndBalances();
  }, [currentUser.token]);

  const formatBalance = (balance, coinType) => {
    let formattedBalance;

    // Handle KES with 2 decimal places
    if (coinType === "KES") {
      formattedBalance = balance.toFixed(2); 
    } else {
      formattedBalance = balance.toFixed(8); 
    }

    // Remove trailing zeros
    formattedBalance = parseFloat(formattedBalance).toString();

    if (coinType === "KES" || ("USDT" && !formattedBalance.includes("."))) {
      formattedBalance += ".00";
    } else if (formattedBalance.indexOf(".") !== -1) {
      // For other coins, truncate trailing zeros after the decimal point
      formattedBalance = formattedBalance
        .replace(/(\.\d+?)0+$/, "$1")
        .replace(/\.$/, "");
    }

    return formattedBalance;
  };

  if (loading) {
    return <SkeletonShowBalanceCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h6"
        sx={{ marginBottom: 2, fontSize: { xs: 40, md: 20, lg: 16 } }}
      >
        Coin Balances
      </Typography>
      {coins.length === 0 ? (
        <Card
          p={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1" p={3}>
            No coin balances found.
          </Typography>
        </Card>
      ) : (
        coins.map((coin) => (
          <Card
            key={coin.coin_id}
            sx={{
              padding: 3,
              borderRadius: 2,
              fontSize: { xs: 38, md: 20, lg: 16 },
              position: "relative",
              overflow: "hidden",
              marginBottom: 2,
              boxShadow: theme.shadows[3],
              transition: "0.3s",
              "&:hover": {
                boxShadow: theme.shadows[6],
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 1rem",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    fontSize: { xs: 38, md: 20, lg: 16 },
                  }}
                >
                  {coin.coin_label} Balance
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ marginTop: 1, fontSize: { xs: 38, md: 20, lg: 16 } }}
                >
                  {coin.balance !== undefined && coin.balance !== null ? (
                    <Box
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Show formatted balance */}
                      {formatBalance(coin.balance, coin.coin_id)}
                    </Box>
                  ) : (
                    <Box
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {"No balance data"}
                    </Box>
                  )}
                </Typography>
              </Box>
              <img
                src={coin.icon_style}
                alt={`${coin.coin_name} icon`}
                style={{ width: 32, height: 32 }}
              />
            </Box>
          </Card>
        ))
      )}
    </ThemeProvider>
  );
};

export default BalanceWidget;
