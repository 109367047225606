import React, { useState, useEffect, useContext } from "react";
import {
  Snackbar,
  Paper,
  Button,
  TableCell,
  Box,
  useTheme,
  InputBase,
  Grid,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider } from "@emotion/react";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import LoadingData from "../Loaders/Loading";

// Custom styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.responsiveFontSizes,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 6,
  fontSize: theme.typography.responsiveFontSizes,
}));

const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.alert.disabled,
  margin: theme.spacing(2),
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    maxWidth: "none",
  },
}));

const SearchIconWrapper = styled(Box)({
  padding: "10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: "40px",
  flex: 1,
  "& input": {
    padding: "10px 12px",
    width: "100%",
  },
}));

const ClearIconWrapper = styled(IconButton)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function CustomToolbar({ searchText, setSearchText }) {
  const theme = useTheme();

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
            >
              <ClearIcon />
            </ClearIconWrapper>
          )}
        </Search>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.primary,
            "& .MuiTypography-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButtonBase-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-label": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <GridToolbar />
        </GridToolbarContainer>
      </Box>
      <Divider />
    </ThemeProvider>
  );
}

const Datatable = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();
  const { userId } = useParams();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await apiServiceFetchers.getUsers(currentUser.token);
        setData(users);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Failed to fetch users data.");
      }
    };
    fetchData();
  }, [currentUser.token, userId]);

  const filterData = (data, searchText) => {
    if (!data) return [];

    // Normalize search text for comparison
    const normalizedSearchText = searchText.toLowerCase();

    return data.filter((user) => {
      // Normalize the user's phone number
      let cleanPhoneNumber = user.phone.startsWith("+")
        ? user.phone // Keep '+' in the phone number
        : user.phone.replace(/\D/g, ""); // Otherwise, remove non-digit characters

      // Adjust phone number to handle case with leading '0'
      const localPhoneNumber = cleanPhoneNumber.startsWith("0")
        ? cleanPhoneNumber
        : "0" + cleanPhoneNumber.replace(/^\+?\d{1,3}/, ""); // Add '0' after stripping country code

      // Convert user fields to lowercase for comparison
      const firstNameMatch = (user.firstName || "")
        .toLowerCase()
        .includes(normalizedSearchText);
      const lastNameMatch = (user.lastName || "")
        .toLowerCase()
        .includes(normalizedSearchText);
      const emailMatch = (user.email || "")
        .toLowerCase()
        .includes(normalizedSearchText);

      const phoneMatch =
        cleanPhoneNumber.includes(searchText) ||
        localPhoneNumber.includes(searchText);

      // Return true if any of the fields match
      return firstNameMatch || lastNameMatch || emailMatch || phoneMatch;
    });
  };

  const filteredData = filterData(data, searchText);

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1, minWidth: 150 },
    { field: "lastName", headerName: "Last Name", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone", headerName: "Phone", flex: 1, minWidth: 150 },
    {
      field: "verificationStatus",
      headerName: "Verification Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box
          p={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            borderRadius: "4px",
            padding: "3px",
            opacity: "0.7",
            width: "100px",
            backgroundColor:
              params.row.verificationStatus === "verified"
                ? "green"
                : theme.palette.alert.warning,
          }}
        >
          {params.row.verificationStatus === "verified"
            ? "verified"
            : "unverified"}
        </Box>
      ),
    },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span className={`status ${params.value}`}>
          {params.value
            ? new Date(params.value).toLocaleDateString("en-GB")
            : "-"}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <StyledTableCell>
          <StyledButton
            startIcon={<VisibilityIcon />}
            onClick={() => navigate(`/users/userdetails/${params.row.id}`)}
          >
            View User
          </StyledButton>
        </StyledTableCell>
      ),
    },
  ];

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ height: "100%", width: "100%" }}>
        {loading ? (
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <LoadingData />
          </Grid>
        ) : filteredData.length > 0 ? (
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Box sx={{ minWidth: 900 }}>
              <DataGrid
                rows={filteredData}
                columns={columns}
                autoHeight
                pagination
                page={page}
                pageSize={pageSize}
                rowCount={filteredData.length}
                paginationMode="server"
                onPageChange={(params) => handleChangePage(params.page)}
                onPageSizeChange={(params) =>
                  handlePageSizeChange(params.pageSize)
                }
                rowsPerPageOptions={[10, 25, 50]}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  toolbar: { searchText, setSearchText },
                }}
                sx={{
                  "& .MuiDataGrid-cell": {
                    fontSize: theme.typography.responsiveFontSizes,
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: theme.typography.responsiveFontSizes,
                  },
                  padding: "2%",
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          ></Box>
        )}

        {/* Additional check for filteredData length */}
        {!loading && filteredData.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="h5" mb={2}>
              No User found
            </Typography>
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
              sx={{ marginBottom: 2 }}
            >
              <ClearIcon />
            </ClearIconWrapper>
          </Box>
        )}
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </ThemeProvider>
  );
};

export default Datatable;
