import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  MenuItem,
  Menu
} from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";

function Navbar({ toggleMode }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { currentUser } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      try {
        const data = await apiServiceFetchers.getUnreadNotifications(
          currentUser.token
        );
        setUnreadCount(data.count);
      } catch (error) {}
    };

    fetchUnreadNotifications();
  }, [currentUser.token]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        style: {
          width: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }
      }}
    >
      <MenuItem onClick={handleMenuClose} component={Link} to="/profile">
        Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/logout">
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={toggleMode}>
        <IconButton size="large" aria-label="dark mode toggle" color="inherit">
          <DarkModeOutlinedIcon />
        </IconButton>
        <Typography
          variant="inherit"
          sx={{ color: theme.palette.text.primary }}
        >
          Toggle Mode
        </Typography>
      </MenuItem>
      <MenuItem component={Link} to="/notifications">
        <IconButton
          size="large"
          aria-label="show unread notifications"
          color="inherit"
        >
          <Badge badgeContent={unreadCount || 0} color="error">
            <NotificationsIcon sx={{ color: theme.palette.text.primary }} />
          </Badge>
        </IconButton>
        <Typography
          variant="inherit"
          sx={{ color: theme.palette.text.primary }}
        >
          Notifications
        </Typography>
      </MenuItem>
      <MenuItem component={Link} to="/profile">
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Typography
          variant="inherit"
          sx={{ color: theme.palette.text.primary }}
        >
          Profile
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        height: { xs: "90px", lg: "70px" }
      }}
    >
      <Toolbar
        sx={{
          height: { xs: "80px" },
          paddingLeft: isMobile ? "16px" : "24px", // Adjust padding for mobile
          paddingRight: isMobile ? "16px" : "24px" // Adjust padding for mobile
        }}
      >
        <Typography
          noWrap
          component="div"
          sx={{
            display: { xs: "none", sm: "block" },
            color: theme.palette.text.primary,
            fontSize: { xs: "0.85rem", lg: "1rem" }
          }}
        >
          Admin Dashboard
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {isMobile ? (
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{
                "&:hover": {
                  backgroundColor: theme.palette.background.paper1
                }
              }}
            >
              <MoreIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ display: "flex" }}>
            <IconButton
              size="large"
              aria-label="dark mode toggle"
              color="inherit"
              onClick={toggleMode}
            >
              <Badge>
                <DarkModeOutlinedIcon
                  sx={{ color: theme.palette.text.primary }}
                />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              aria-label="show unread notifications"
              color="inherit"
              component={Link}
              to="/notifications"
            >
              <Badge badgeContent={unreadCount || 0} color="error">
                <NotificationsIcon sx={{ color: theme.palette.text.primary }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Box>
        )}
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}

export default Navbar;
