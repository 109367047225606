// faqsActions.js
import apiServiceFetchers from "../../services/apiServiceFetchers";
import {
  FETCH_FAQS_REQUEST,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_FAILURE,
} from "../ActionTypes";

export const fetchFaqs = (token) => async (dispatch) => {
  dispatch({ type: FETCH_FAQS_REQUEST });

  try {
    const data = await apiServiceFetchers.getAllFaqs(token);
    dispatch({ type: FETCH_FAQS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: FETCH_FAQS_FAILURE, error: error.message });
  }
};
