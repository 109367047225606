import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from "../../redux/ActionTypes";

const initialState = {
  showNotifications: [],
  loadingNotifications: false,
  error: null,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingNotifications: true,
        error: null,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        showNotifications: action.payload,
        loadingNotifications: false,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
        error: action.error,
      };
    default:
      return state;
  }
};

