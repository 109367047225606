import { Navigate } from "react-router-dom";

export const RequireAdminAuth = ({ currentUser, children }) => {

  if (!currentUser || !currentUser.roles.includes("ROLE_ADMIN")) {
    return <Navigate to="/login" />;
  }

  return children;
};
