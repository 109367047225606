import React, { useState, useEffect, useContext } from "react";
import {
  Snackbar,
  Paper,
  Box,
  useTheme,
  InputBase,
  Divider,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import { ThemeProvider } from "@emotion/react";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import LoadingData from "../Loaders/Loading";

// Custom styled components
const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.alert.disabled,
  margin: theme.spacing(2),
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    maxWidth: "none",
  },
}));

const SearchIconWrapper = styled(Box)({
  padding: "10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: "40px",
  flex: 1,
  "& input": {
    padding: "10px 12px",
    width: "100%",
  },
}));

const ClearIconWrapper = styled(IconButton)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function CustomToolbar({ searchText, setSearchText }) {
  const theme = useTheme();

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
            >
              <ClearIcon />
            </ClearIconWrapper>
          )}
        </Search>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.primary,
            "& .MuiTypography-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButtonBase-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-label": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <GridToolbar />
        </GridToolbarContainer>
      </Box>
      <Divider />
    </ThemeProvider>
  );
}

const formatDate = (date) => {
  if (!date) return "";

  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) return "";

  return parsedDate.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

const RevenueTable = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { userId } = useParams();
  const [setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiServiceFetchers.getRevenue(currentUser.token);
        const revenues = response.transactions || [];
        setData(revenues);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Failed to fetch revenues data.");
      }
    };
    fetchData();
  }, [currentUser.token, userId]);

  const filterData = (data, searchText) => {
    if (!data) return [];
    return data.filter(
      (revenue) =>
        (revenue.transactionKind &&
          revenue.transactionKind
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (revenue.transactionType &&
          revenue.transactionType
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (revenue.id &&
          revenue.id.toLowerCase().includes(searchText.toLowerCase()))
    );
  };

  const filteredData = filterData(data, searchText);
  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 200,
      renderCell: (params) => {
        const truncatedId = params.value.split("-")[0].toUpperCase();
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {truncatedId}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 200,
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    { field: "transaction_kind", headerName: "Coin", width: 200 },
    { field: "transaction_type", headerName: "Transaction Type", width: 200 },
    { field: "transaction_charge_type", headerName: "Charge Type", width: 200 },
    {
      field: "transaction_charge_amount",
      headerName: "Fee Amount",
      width: 200,
      renderCell: (params) => {
        // Check if the value is undefined, null, or cannot be parsed as a number
        if (
          params.value === undefined ||
          params.value === null ||
          isNaN(parseFloat(params.value))
        ) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {"-"}
            </Box>
          );
        }
  
        const amount = parseFloat(params.value);
  
        // Determine if the transaction kind is "mpesa" or another cryptocurrency
        const isCrypto = params.row.transaction_kind === "mpesa";
        
        // Format the amount based on whether it's crypto or not
        const formattedAmount = isCrypto
          ? amount.toFixed(2)
          : amount.toFixed(8);
  
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formattedAmount}
          </Box>
        );
      },
    },
    {
      field: "pay_coin_amount",
      headerName: "Transaction Amount",
      width: 200,
      renderCell: (params) => {
        if (params.value === undefined || params.value === null) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {""}
            </Box>
          );
        }

        const amount = parseFloat(params.value);

        if (isNaN(amount) || !isFinite(amount)) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              Error
            </Box>
          );
        }

        const isCrypto = params.row.transaction_kind === "mpesa";

        const formattedAmount = isCrypto
          ? amount.toFixed(2)
          : amount.toFixed(8);

        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formattedAmount}
          </Box>
        );
      },
    },
    {
      field: "transaction_status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Box
          p={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            borderRadius: "4px",
            padding: "3px",
            opacity: "0.7",
            width: "100px",
            backgroundColor:
              params.row.transaction_status === "completed"
                ? "green"
                : params.row.transaction_status === "pending"
                ? theme.palette.alert.disabled
                : params.row.transaction_status === "failed"
                ? "red"
                : "green",
          }}
        >
          {params.row.transaction_status}
        </Box>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        mt: 2,
      }}
    >
      {loading ? (
        <LoadingData />
      ) : (
        <Box sx={{ height: "calc(100vh - 200px)", width: "100%" }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            components={{ Toolbar: CustomToolbar }}
            componentsProps={{
              toolbar: {
                searchText,
                setSearchText,
              },
            }}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default RevenueTable;
