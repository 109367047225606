import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_GOCHAPAA_API_ENDPOINT;

const apiService = {
  // TO UPDATE A SINGLE COIN
  updateCoin: async (id, updatedFields, token) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/coinList/update/${id}`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateFaqs: async (id, updatedFields, token) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/faqs/update/${id}`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateNotificationAsRead: async (id, updatedFields, token) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/v1/notification/read/${id}`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updatePassword: async (updatedFields, token) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/auth/me/password`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateCurrentUserData: async (updatedFields, token) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/auth/me`,
        updatedFields,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // approve withdrawal request
  approvalWithdrawalRequest: async (id, token) => {
    const url = `${API_BASE_URL}/api/v1/withdrawal-request/approve/${id}`;
    try {
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  rejectWithdrawalRequest: async (id, token) => {
    // No need for updatedFields in this case
    const url = `${API_BASE_URL}/api/v1/withdrawal-request/reject/${id}`;
    try {
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO UPDATE A COIN STATUS
  updateCoinStatus: async (coinId, data, token) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/v1/coinList/coin/${coinId}/activate-deactivate`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update coin status");
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  },
};

export default apiService;
