import React, { useState, useEffect, useContext } from "react";
import { Snackbar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../Auth/AuthContext";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import { handleApiError } from "../../constants/helpers";
import LoadingData from "../Loaders/Loading";

const TransactionById = () => {
  const theme = useTheme();
  const { userId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const userTransactionDetails =
          await apiServiceFetchers.getTransactionByUserId(
            userId,
            currentUser.token
          );

        // Validate the response structure
        if (
          userTransactionDetails &&
          userTransactionDetails.success &&
          userTransactionDetails.transactions &&
          Array.isArray(userTransactionDetails.transactions)
        ) {
          setTransactions(userTransactionDetails.transactions);
        } else {
          setTransactions([]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error, setOpenSnackbar, setSnackbarMessage);
      }
    };

    fetchTransactions();
  }, [userId, currentUser.token]);

  const formatDate = (date) => {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";

    return parsedDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 200,
      renderCell: (params) => {
        const truncatedId = params.value.split("-")[0].toUpperCase();
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {truncatedId}
          </Box>
        );
      },
    },
    { field: "transaction_type", headerName: "Transaction Type", flex: 1 },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    { field: "transaction_kind", headerName: "Coin", flex: 1 },
    {
      field: "pay_coin_amount",
      headerName: "Transaction Amount",
      width: 200,
      renderCell: (params) => (
        <Box
          p={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {params.value !== undefined && params.value !== null
            ? params.value
            : ""}
        </Box>
      ),
    },
    {
      field: "transaction_status",
      headerName: "Transaction Status",
      flex: 1,
      renderCell: (params) => {
        const statusColor =
          params.value === "complete"
            ? "green"
            : params.value === "failed"
            ? "red"
            : theme.palette.alert.disabled;

        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              padding: 1,
              width: {
                xs: "100%", // Width of 100 on extra-small screens
                sm: "100%",
                lg: "50%", // Width of 50 on small screens and larger
              },
              color: "white",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: statusColor,
              borderRadius: 2,
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: "80vh",
        width: "100%",
        p: 2,
        borderRadius: "8px",
        backgroundColor: theme.palette.background.default,
      }}
    >
      {loading ? (
        <LoadingData />
      ) : transactions.length === 0 ? (
        <Typography>No data found</Typography>
      ) : (
        <DataGrid
          rows={transactions}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
        />
      )}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default TransactionById;
