const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        currentUser: action.payload.user,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken
      };
    }
    case "LOGOUT": {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("graphData");
      localStorage.removeItem("balanceData");
      return {
        ...state,
        currentUser: null,
        token: null,
        graphData: null,
        balanceData: []
      };
    }
    case "SET_GRAPH_DATA": {
      return {
        ...state,
        graphData: action.payload
      };
    }
    case "SET_BALANCE_DATA": {
      return {
        ...state,
        balanceData: action.payload
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
