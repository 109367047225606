import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const Logout = () => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        dispatch({ type: "LOGOUT" });

        navigate("/login");

        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("tokenExpiration");
      } catch (error) {
        console.error("Logout Error:", error);
      }
    };

    handleLogout();
  }, [dispatch, navigate]);

  return null;
};

export default Logout;
