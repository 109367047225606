import React, { useEffect, useContext, useState } from "react";
import { ThemeProvider, useTheme } from "@emotion/react";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";

import {
  Box,
  Tab,
  Tabs,
  Avatar,
  Typography,
  Container,
  CssBaseline,
  Card
} from "@mui/material";
import ChangePassword from "./ChangePassword";
import ProfileTab from "./ProfileTab";

function Profile() {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);

  const [value, setValue] = useState(0);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    createdAt: "",
    nationalityName: "",
    subscribedToNewsletters: false,
    profilePicture: null // Add profilePicture field to store the image data
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await apiServiceFetchers.getCurrentUserData(
          currentUser.token
        );
        setProfileData(response);
      } catch (error) {}
    };

    if (currentUser.token) {
      fetchProfileData();
    }
  }, [currentUser.token]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Card>
        <Container maxWidth="md">
          <Box
            display="flex"
            flexDirection="row"
            mt={5}
            sx={(theme) => ({
              textAlign: "center",
              [theme.breakpoints.up("xxl")]: { textAlign: "left" }
            })}
          >
            <Box
              flex={1}
              p={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Avatar
                alt={`${profileData.firstName.toLocaleUpperCase()} profile picture`}
                src={
                  profileData.profilePicture
                    ? profileData.profilePicture.filePath
                    : "profile-pic.jpg"
                }
                sx={{
                  width: 150,
                  height: 150,
                  mb: 2
                }}
              />
              <Typography
                variant="h5"
                sx={{ fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" } }}
              >
                {profileData.firstName} {profileData.lastName}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" } }}
              >
                The Admin GoChapaa
              </Typography>
            </Box>
            <Box flex={2} p={2}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" }
                }}
              >
                <Tab
                  label="Profile"
                  sx={{
                    width: "50%",
                    color: theme.palette.text.primary,
                    fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" },
                    "&:hover": {
                      color: theme.palette.text.primary
                    }
                  }}
                />
                <Tab
                  label="Change Password"
                  sx={{
                    width: "50%",
                    fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" },
                    color: theme.palette.text.primary
                  }}
                />
              </Tabs>
              {value === 0 && (
                <ProfileTab
                  profileData={profileData}
                  setProfileData={setProfileData}
                />
              )}
              {/* Pass profileData as prop */}
              {value === 1 && <ChangePassword />}
            </Box>
          </Box>
        </Container>
      </Card>
    </ThemeProvider>
  );
}

export default Profile;
