import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../Auth/AuthContext";
import {
  Box,
  Button,
  Card,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import logo from "../Assets/Light-theme-Logo.png";
import loginLogo from "../Assets/digital-money.png";
import apiServiceActions from "../services/apiServiceActions";
import "./login.scss";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
  
      if (email !== "admin@gochapaa.com") {
        throw new Error("Unauthorized email");
      }
  
      const userData = await apiServiceActions.loginUser(email, password);
      const { user } = userData;
      const { token, refreshToken } = user;
  
      const payload = {
        user,
        token,
        refreshToken
      };
  
      dispatch({ type: "LOGIN", payload });
  
      // Set token expiration time (example: 1 hour from now)
      const tokenExpiration = new Date().getTime() + 60 * 60 * 1000;
      localStorage.setItem("tokenExpiration", tokenExpiration);
  
      navigate("/");
    } catch (apiError) {
      console.error("API Error:", apiError);
  
      if (apiError.message === "Network Error") {
        setSnackbarMessage("Network Error: Please check your internet connection and try again.");
      } else if (apiError.message === "Unauthorized email") {
        setSnackbarMessage("Unauthorized email address. Please use a valid email.");
      } else {
        setSnackbarMessage("Invalid email or password. Please check your credentials and try again.");
      }
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box className="container" sx={{ overflowY: "auto" }}>
      <Card className="login-card">
        <Box className="left-section">
          <Typography variant="h4" component="h1">
            Welcome back to
          </Typography>
          <img src={logo} alt="Logo" className="logo" sx={{ marginTop: 4 }} />
          <img
            src={loginLogo}
            alt="Logo"
            className="logo"
            sx={{ height: "100%", width: "100%" }}
          />
        </Box>
        <Box className="right-section">
          <form onSubmit={handleLogin}>
            <TextField
              type="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="input-field"
              sx={{ mb: 2 }}
            />
            <TextField
              type="password"
              placeholder="Pass@123!"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              className="input-field"
              sx={{ mb: 4 }}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{ backgroundColor: theme.palette.background.default }}
              fullWidth
              className="sign-in-button"
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
          </form>
        </Box>
      </Card>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Login;
