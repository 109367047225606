import { Box } from "@mui/material";
import Table from "../../components/table/Table";

const Transactions = () => {
  return (
    <Box>
      <Table />
    </Box>
  );
};

export default Transactions;
