import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import "./single.scss";
import { Box, Card, Typography, useTheme } from "@mui/material";
import List from "../../Pages/list/List";
import { ThemeProvider } from "@emotion/react";
import LoadingData from "../../components/Loaders/Loading";

const Single = () => {
  const theme = useTheme(); // Access the current theme
  // Get currentUser and token from AuthContext
  const { currentUser } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading
  // Accessing URL parameters using useParams
  const { userId } = useParams();

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await apiServiceFetchers.getUserById(
          userId,
          currentUser.token
        );
        setUserDetails(userDetails);
        setLoading(false); 
      } catch (error) {
        setLoading(false); 
      }
    };
    fetchUserDetails();
  }, [userId, currentUser.token]);

  return (
    <ThemeProvider theme={theme}>
      <Box p={2} >
        <Box className="singleContainer">
          <Card className="top">
            <Box className="left">
              <Box className="editButton">
                <Link
                  to={`/users/userdetails/${userId}`}
                  className="link"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                      padding: 0
                    }}
                  >
                    View Details
                  </button>
                </Link>
              </Box>
              <Typography variant="h6" gutterBottom>
                User Information
              </Typography>
              {/* Replace userDetails conditional rendering with skeleton loader */}
              {!loading ? (
                userDetails && (
                  <Box className="item">
                    <Box className="details">
                      <h1 className="itemTitle">
                        {userDetails.firstName} {userDetails.lastName}
                      </h1>
                      <Box className="detailItem">
                        <span className="itemKey">Email:</span>
                        <span className="itemValue">{userDetails.email}</span>
                      </Box>
                      <Box className="detailItem">
                        <span className="itemKey">Phone:</span>
                        <span className="itemValue">{userDetails.phone}</span>
                      </Box>
                      <Box className="detailItem">
                        <span className="itemKey">Verification Status:</span>
                        <span
                          className="itemValue"
                          style={{
                            color:
                              userDetails.verificationStatus === "verified"
                                ? "green"
                                : "red"
                          }}
                        >
                          {userDetails.verificationStatus}
                        </span>
                      </Box>
                      <Box className="detailItem">
                        <span className="itemKey">Date Created:</span>
                        <span className="itemValue">
                          {new Date(userDetails.createdAt).toLocaleDateString()}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                )
              ) : (
                <LoadingData />
              )}
            </Box>
          </Card>
          <Box paddingTop={2} className="bottom">
            <List />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Single;
