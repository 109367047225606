const initialState = {
    cachedCoinsWithBalances: null,
    loadingCoins: false,
    error: null,
  };
  
  export const coinsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_COINS_REQUEST':
        return {
          ...state,
          loadingCoins: true,
          error: null,
        };
      case 'FETCH_COINS_SUCCESS':
        return {
          ...state,
          cachedCoinsWithBalances: action.payload,
          loadingCoins: false,
        };
      case 'FETCH_COINS_FAILURE':
        return {
          ...state,
          loadingCoins: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  