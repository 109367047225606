import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  useTheme
} from "@mui/material";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import { AuthContext } from "../../Auth/AuthContext";

function ProfileTab({ profileData, setProfileData }) {
  const theme = useTheme();

  const { currentUser } = useContext(AuthContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleSubmit = async () => {
    try {
      await apiServiceUpdate.updateCurrentUserData(
        profileData,
        currentUser.token
      );
      setSnackbarMessage("Profile updated successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to update profile");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={3}>
      <Box>
        <TextField
          label="First Name"
          name="firstName"
          value={profileData.firstName}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Last Name"
          name="lastName"
          value={profileData.lastName}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Email Address"
          name="email"
          value={profileData.email}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Phone Number"
          name="phone"
          value={profileData.phone}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Joining Date"
          value={
            profileData.createdAt
              ? new Date(profileData.createdAt).toLocaleDateString()
              : ""
          }
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true
          }}
        />
        <TextField
          label="Country"
          name="nationalityName"
          value={profileData.nationalityName}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Button
          type="submit"
          variant="outlined"
          fullWidth
          onClick={handleSubmit}
          sx={{
            width: "100%",
            color: theme.palette.text.primary,
            fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" },
            backgroundColor: theme.palette.button.primary
          }}
        >
          Save Changes
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            backgroundColor:
              snackbarSeverity === "error"
                ? "red"
                : theme.palette.button.primary
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ProfileTab;
