import { Box, Typography,Card } from "@mui/material";
import RevenueTable from "../../components/revenueTable/revenueTable";

const Revenue = () => {
  return (
    <Card>
     <Typography
       sx={{
         padding: 1,
         fontWeight: "bold",
         fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" }
       }}
       gutterBottom
     >
       Revenue
     </Typography>
     <Box>
     <RevenueTable />
     </Box>
   </Card>
  );
};

export default Revenue;
