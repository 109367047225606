import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
} from "react";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Chart from "chart.js/auto";
import "react-circular-progressbar/dist/styles.css";
import "./featured.scss";
import { SkeletonShowCircleChartsCards } from "../Loaders/Skeleton";
import {
  getRandomColor,
  formatNumberWithCommas,
} from "../../constants/helpers"; // Make sure this function is imported

const Featured = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [completeBalanceData, setCompleteBalanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);
  const [coinData, setCoinData] = useState([]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        // Fetch coin data
        const coinResponse = await apiServiceFetchers.getAllCoins(
          currentUser.token
        );
        if (coinResponse && coinResponse.coins) {
          setCoinData(coinResponse.coins);
        }

        // Fetch completed transactions
        const transactionResponse =
          await apiServiceFetchers.getcompletedTransactionsEachAsset(
            currentUser.token
          );
        setCompleteBalanceData(transactionResponse);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchChartData();
  }, [currentUser.token]);

  // Function to convert balance to USD
  // Memoize the convertToUSD function using useCallback
  const convertToUSD = useCallback(
    (amount, coinId) => {
      const coin = coinData.find((coin) => coin.coin_id === coinId);

      if (coin && coin.price) {
        const conversionRate = coin.price;
        const convertedBalance = amount * conversionRate;
        return convertedBalance.toFixed(2);
      } else {
        return "N/A";
      }
    },
    [coinData] // Dependencies of the callback function
  );

  useEffect(() => {
    const canvas = chartRef.current;
    if (canvas && !loading) {
      // Map data to chart format
      const labels = [];
      const data = [];

      // Define theme colors
      const themeColors = [
        theme.palette.alert.disabled,
        theme.palette.background.paper2,
        theme.palette.alert.warning,
        theme.palette.alert.success,
      ];

      // Generate random colors
      const randomColors = Array.from(
        { length: completeBalanceData.length },
        getRandomColor
      );

      // Combine theme colors and random colors
      const backgroundColor = [
        ...themeColors.slice(0, Math.min(4, completeBalanceData.length)),
        ...randomColors.slice(0, Math.max(0, completeBalanceData.length - 4)),
      ].slice(0, completeBalanceData.length);

      completeBalanceData.forEach(({ coinId, totalAmount }) => {
        const amountInUSD = parseFloat(convertToUSD(totalAmount, coinId));
        if (!isNaN(amountInUSD)) {
          labels.push(coinId);
          data.push(amountInUSD);
        }
      });

      const chartInstance = new Chart(canvas, {
        type: "doughnut",
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColor,
            },
          ],
        },
        options: {
          cutout: "75%",
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  let label = context.label || "";
                  if (label) {
                    const value = context.raw || 0;
                    const total = context.chart.data.datasets[0].data.reduce(
                      (acc, val) => acc + val,
                      0
                    );
                    const percentage = Math.round((value / total) * 100);
                    label += `: ${percentage}%`;
                  }
                  return label;
                },
              },
            },
          },
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [theme.palette, loading, completeBalanceData, coinData, convertToUSD]);

  if (loading) {
    return <SkeletonShowCircleChartsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        className="featured"
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRadius: "12px",
          boxShadow: theme.shadows[1],
          height: "auto",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 30, md: 30, lg: 20 },
              fontWeight: 600,
              marginTop: 1,
              textAlign: "center",
              marginBottom: 3,
            }}
          >
            Transaction Volume
          </Typography>
        </Box>
        <Box className="bottom">
          {!loading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
                marginBottom: 6.5,
              }}
            >
              <Box
                sx={{
                  marginTop: 4,
                  display: "flex",
                  alignItems: "flex-start",
                  marginLeft: 10,
                }}
              >
                <canvas ref={chartRef}></canvas>
              </Box>
              <Box
                sx={{
                  width: "1px",
                  backgroundColor: theme.palette.divider,
                  height: "auto",
                  margin: "2px 1px",
                }}
              />
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: 38, md: 20, lg: 16 },
                    marginBottom: 2,
                    fontWeight: 600,
                    marginRight: "4px",
                  }}
                >
                  Sum Of Completed Transactions
                </Typography>
                {completeBalanceData.map(({ coinId, totalAmount }) => {
                  // Use 0 if totalAmount is null or undefined
                  const displayedAmount = totalAmount != null ? totalAmount : 0;

                  const formattedAmount = Number.isInteger(displayedAmount)
                    ? displayedAmount.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })
                    : displayedAmount.toLocaleString(undefined, {
                        maximumFractionDigits: 8,
                      });


                  return (
                    <Box key={coinId} sx={{ marginBottom: 2, marginLeft: 4 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 38, md: 20, lg: 16 },
                            marginBottom: "1px",
                            marginRight: "12px",
                            color: theme.palette.text.main,
                          }}
                        >
                          {coinId}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 38, md: 20, lg: 16 },
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {formattedAmount}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 1,
                          marginLeft: 6,
                        }}
                      >
                        <Typography
                          sx={{
                            color: theme.palette.text.secondary,
                            fontSize: { xs: 38, md: 20, lg: 16 },
                          }}
                        >
                          {formatNumberWithCommas(
                            Number(convertToUSD(displayedAmount, coinId))
                          )}{" "}
                          <span>USD</span>
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default Featured;
