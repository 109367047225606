import React from "react";
import { Typography, Paper, Card, Box } from "@mui/material";

const TermsOfUse = () => {
  return (
    <Paper elevation={2}>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h5" gutterBottom>
          GOCHAPAA APP TERMS OF USE AGREEMENT - Reviewed
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Last updated May 19, 2022
        </Typography>
        <Typography variant="body1" gutterBottom>
          GoChapaa App (the “Application”) is licensed to You (End-User) by
          Sukuma Ventures PTE, LTD, located at 80 Robinson Road #02-00 Singapore
          068898, Singapore, Singapore (hereinafter “Licensor”), for use only
          under the terms of this License Agreement. By downloading the
          Application and any update thereto (as permitted by this License
          Agreement), You indicate that You agree to be bound by all of the
          terms and conditions of this License Agreement, and that You accept
          this License Agreement. The parties of this License Agreement
          acknowledge that each of Apple and Google is not a Party to this
          License Agreement and is not bound by any provisions or obligations
          with regard to the Application, such as warranty, liability,
          maintenance and support thereof. Licensor is solely responsible for
          the licensed Application and the contents thereof. This License
          Agreement may not provide for usage rules for the Application that are
          in conflict with the latest Terms of Service. Licensor acknowledges
          that it had the opportunity to review said terms and this License
          Agreement is not conflicting with them. All rights not expressly
          granted to You are reserved.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}>1. THE APPLICATION</Box>
          GoChapaa App or the Application is a piece of software created to
          allow users to buy and sell crypto currencies with their local
          currencies - and customized for mobile devices.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 2. SCOPE OF LICENSE </Box>
          <Typography>
            2.1 You may not share or make the Application available to third
            parties (unless to the degree allowed by the Apple Appstore and
            Google Play Terms and Conditions, and with Licensor's prior written
            consent), sell, rent, lend, lease or otherwise redistribute the
            Application.
          </Typography>
          <Typography>
            2.2 You may not reverse engineer, translate, disassemble, integrate,
            decompile, integrate, remove, modify, combine, create derivative
            works or updates of, adapt, or attempt to derive the source code of
            the Application, or any part thereof (except with Licensor’s prior
            written consent).{" "}
          </Typography>
          <Typography>
            {" "}
            2.3 You may not copy (excluding when expressly authorized by this
            license and the Usage Rules) or alter the Application or portions
            thereof. You may create and store copies only on devices that You
            own or control for backup keeping under the terms of this license,
            the App Store Terms of Service, and any other terms and conditions
            that apply to the device or software used. You may not remove any
            intellectual property notices. You acknowledge that no unauthorized
            third parties may gain access to these copies at any time
          </Typography>
          <Typography>
            2.4 Violations of the obligations mentioned above, as well as the
            attempt of such infringement, may be subject to prosecution and
            damages.{" "}
          </Typography>
          <Typography>
            {" "}
            2.5 Licensor reserves the right to modify the terms and conditions
            of licensing.
          </Typography>
          <Typography>
            {" "}
            2.6 Nothing in this license should be interpreted to restrict
            third-party terms. When using the Application, You must ensure that
            You comply with applicable third-party terms and conditions.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 3. TECHNICAL REQUIREMENTS </Box>
          <Typography>
            {" "}
            3.1 Licensor attempts to keep the Application updated so that it
            complies with modified/new versions of the firmware and new
            hardware. You are not granted rights to claim such an update.
          </Typography>
          <Typography>
            3.2 You acknowledge that it is Your responsibility to confirm and
            determine that the app end-user device on which You intend to use
            the Application satisfies the required technical specifications.
          </Typography>
          <Typography>
            {" "}
            3.3 Licensor reserves the right to modify the technical
            specifications as it sees appropriate at any time.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 4. MAINTENANCE AND SUPPORT </Box>
          <Typography>
            {" "}
            4.1 Licensor is solely responsible for providing any maintenance and
            support services for this licensed Application. You can reach
            Licensor at the email address listed in the App Store Overview for
            this licensed Application.
          </Typography>
          <Typography>
            {" "}
            4.2 Licensor and You acknowledge that each of Apple and Google has
            no obligation whatsoever to furnish any maintenance and support
            services with respect to the licensed Application.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}>5. USER GENERATED CONTRIBUTIONS </Box>
          <Typography>
            {" "}
            The Application may invite you to chat, contribute to, or
            participate in blogs, message boards, online forums, and other
            functionality, and may provide you with the opportunity to create,
            submit, post, display, transmit, perform, publish, distribute, or
            broadcast content and materials to us or in the Application,
            including but not limited to bid, writings, video, audio,
            photographs, graphics, comments, suggestions, or personal
            information or other material (collectively, "Contributions").
            Contributions may be viewable by other users of the Application and
            through third-party websites or applications. As such, any
            Contributions you transmit may be treated as non-confidential and
            non-proprietary. When you create or make available any
            Contributions, you thereby represent and warrant that:{" "}
          </Typography>
          <Typography>
            {" "}
            1. The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.{" "}
          </Typography>
          <Typography>
            {" "}
            2. You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Application, and other users of the Application to use your
            Contributions in any manner contemplated by the Application and
            these Terms of Use.
          </Typography>
          <Typography>
            {" "}
            3. You have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to
            use the name or likeness or each and every such identifiable
            individual person to enable inclusion and use of your Contributions
            in any manner contemplated by the Application and these Terms of
            Use.
          </Typography>
          <Typography>
            {" "}
            4. Your Contributions are not false, inaccurate, or misleading.
          </Typography>
          <Typography>
            {" "}
            5. Your Contributions are not unsolicited or unauthorized
            advertising, promotional materials, pyramid schemes, chain letters,
            spam, mass mailings, or other forms of solicitation.
          </Typography>
          <Typography>
            {" "}
            6. Your Contributions are not obscene, lewd, lascivious, filthy,
            ablest, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </Typography>
          <Typography>
            {" "}
            7. Your Contributions do not ridicule, mock, disparage, intimidate,
            or abuse anyone.{" "}
          </Typography>
          <Typography>
            {" "}
            8. Your Contributions are not used to harass or threaten (in the
            legal sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </Typography>
          <Typography>
            {" "}
            9. Your Contributions do not violate any applicable law, regulation,
            or rule.
          </Typography>
          <Typography>
            {" "}
            10. Your Contributions do not violate the privacy or publicity
            rights of any third party.
          </Typography>
          <Typography>
            {" "}
            11. Your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.{" "}
          </Typography>
          <Typography>
            {" "}
            12. Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-bong of minors.{" "}
          </Typography>
          <Typography>
            {" "}
            13. Your Contributions do not include any offensive comments that
            are connected to race, national origin, gender, sexual preference,
            or physical handicap.{" "}
          </Typography>
          <Typography>
            {" "}
            14. Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation. Any use of the Application in
            violation of the foregoing violates these Terms of Use and may
            result in, among other things, termination or suspension of your
            rights to use the Application.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}>6. CONTRIBUTION LICENSE </Box>
          <Typography>
            {" "}
            By posting your Contributions to any part of the Application or
            making Contributions accessible to the Application by linking your
            account from the Application to any of your social networking
            accounts, you automatically grant, and you represent and warrant
            that you have the right to grant, to us an unrestricted, unlimited,
            irrevocable, perpetual, non-exclusive, transferable, royalty-free,
            fully-paid, worldwide right, and license to host, use copy,
            reproduce, disclose, sell, resell, publish, broad cast, retitle,
            archive, store, cache, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial advertising, or otherwise, and to
            prepare derivative works of, or incorporate in other works, such as
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels. This license will apply to any form, media, or
            technology now known or hereafter developed, and includes our use of
            your name, company name, and franchise name, as applicable, and any
            of the trademarks, service marks, trade names, logos, and personal
            and commercial images you provide. You waive all moral rights in
            your Contributions, and you warrant that moral rights have not
            otherwise been asserted in your Contributions. We do not assert any
            ownership over your Contributions. You retain full ownership of all
            of your Contributions and any intellectual property rights or other
            proprietary rights associated with your Contributions. We are not
            liable for any statements or representations in your Contributions
            provided by you in any area in the Application. You are solely
            responsible for your Contributions to the Application and you
            expressly agree to exonerate and indemnify us from any and all
            responsibilities, losses and liabilities and to refrain from taking
            any legal action against us regarding your Contributions. We have
            the right, in our sole and absolute discretion:
          </Typography>
          <Typography>
            (1) to edit, redact, or otherwise change any Contributions;
          </Typography>
          <Typography>
            {" "}
            (2) to re-categorize any Contributions to place them in more
            appropriate locations in the Application; and
          </Typography>
          <Typography>
            (3) to pre-screen or delete any Contributions at any time and for
            any reason, without notice. We have no obligation to monitor your
            Contributions.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 7. LIABILITY </Box>

          <Typography>
            7.1 Licensor's responsibility in the case of violation of
            obligations and tort shall be limited to willful misconduct and
            gross negligence. In any case, liability shall be limited to the
            actual, direct, foreseeable, contractually typical damages. The
            limitation mentioned above does not apply to injuries to life, limb,
            or health.
          </Typography>
          <Typography>
            7.2 Licensor takes no accountability or responsibility for any
            damages caused due to or in connection with a breach of duties under
            Section 2 of this Agreement. To avoid data loss, You are required to
            make use of backup functions of the Application to the extent
            allowed by applicable third-party terms and conditions of use. You
            are aware that in case of alterations or manipulations of the
            Application, You will not have access to licensed Application.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 8. WARRANTY </Box>
          <Typography>
            8.1 Sukuma Ventures PTE warrants that the Application is free of
            spywares, trojan horses, viruses, or any other malwares at the time
            of Your download. Sukuma Ventures PTE warrants that the Application
            works as described in the user documentation.
          </Typography>
          <Typography>
            {" "}
            8.2 No warranty is provided for the Application that is not
            executable on the device, that has been unauthorizedly modified,
            handled inappropriately or culpably, combined or installed with
            inappropriate hardware or software, used with inappropriate
            accessories, regardless whether caused by Yourself or by third
            parties, or if there are any other reasons outside of Licensor’s
            sphere of influence that affect the executability of the
            Application.
          </Typography>
          <Typography>
            {" "}
            8.3 You are required to inspect the Application immediately after
            installing it and notify Sukuma Ventures PTE, LTD of any issues
            discovered without delay by e-mail provided in Product Claims. The
            defect report will be taken into consideration and further
            investigated if it has been mailed within a period of thirty (30)
            days after discovery.
          </Typography>
          <Typography>
            8.4 If we confirm that the Application is defective, Sukuma Ventures
            PTE, LTD reserves a choice to remedy the situation either by means
            of solving the defect or substitute delivery.
          </Typography>
          <Typography>
            {" "}
            8.5 In the event of any failure of the Application to conform to any
            applicable warranty, You may notify the App-Store-Operator, and Your
            Application purchase price will be refunded to You. To the maximum
            extent permitted by applicable law, the App-Store-Operator will have
            no other warranty obligation whatsoever with respect to the
            Application, and any other loss, claims, damages, liabilities,
            expenses and costs attributable to any negligence to adhere to any
            warranty.
          </Typography>
          <Typography>
            8.6 If the user is an entrepreneur, any claim based on defect, fault
            or otherwise expires after a period of twelve (12) months after the
            Application was made available to the user and You hereby
            acknowledge and agree to such time limit. The statutory periods of
            limitation given by law apply for users who are individual
            consumers.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 9. PRODUCT CLAIMS </Box>
          <Typography>
            Sukuma Ventures PTE, LTD and the End-User acknowledge that Sukuma
            Ventures PTE, LTD, and not Apple or Google, is responsible for
            addressing any claims of the End-User or any third party relating to
            the licensed Application or the End-User's possession and/or use of
            that licensed Application, including, but not limited to:
          </Typography>
          <Typography>(i) product liability claims;</Typography>
          <Typography>
            {" "}
            (ii) any claim that the licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </Typography>
          <Typography>
            (iii) claims arising under consumer protection, privacy, or similar
            legislation
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 10. LEGAL COMPLIANCE </Box>
          <Typography>You represent and warrant that You are</Typography>
          <Typography>
            (a) if an individual, an adult over the age of 18 or the higher age
            under the applicable law of your residence, or if a company, a
            company duly incorporated and validly existing under its place of
            incorporation, and
          </Typography>
          <Typography>
            {" "}
            (b) have the necessary power and authority to enter into and perform
            this License Agreement. You represent and warrant that You are not
            located in a country that is subject to any economic or financial
            sanctions, trade embargoes, export controls or restrictive measures
            administered, enacted, imposed, or enforced from time to time by{" "}
          </Typography>
          <Typography>
            {" "}
            (a) the United States (including the United States Department of
            Treasury’s Office of Foreign Assets Control, the United States
            Department of Commerce’s Bureau of Industry and Security, or the
            United States Department of State), or
          </Typography>
          <Typography>
            (b) the United Nations Security Council, any United Nations Security
            Council Sanctions Committee, the State Secretariat for Economic
            Affairs of Switzerland or the Swiss Directorate of International
            Law, Office of Financial Sanctions Implementation of Her Majesty’s
            Treasury of the United Kingdom, the French Republic, the Federal
            Republic of Germany, the Grand Duchy of Luxembourg and/or the
            European Union, or that has been designated by the U.S. Govement,
            the United Nations or the European Union as a 'terrorist supporting"
            country; and that You are not listed on any list of prohibited or
            restricted parties in the "Consolidated Sanctions List" administered
            by the US Office of Foreign Assets Control (OFAC); the "Entity
            List," "Denied Persons List," and "Unverified List" administered by
            the U.S. Commerce Department's Bureau of Industry and Security
            (BIS); the European Union's Consolidated list of persons, groups and
            entities subject to EU financial sanctions, the UK's Consolidated
            List of Financial Sanctions Targets, the United Nations Security
            Council Consolidated List; or any other similar list maintained by
            any of the United Nations Security Council (or a committee thereof),
            the United States of America, the EU, the UK, Korea, or any other
            government authority with jurisdiction over the activities of.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 11. CONTACT INFORMATION </Box>
          <Typography>
            {" "}
            For general inquiries, complaints, questions or claims concerning
            the licensed Application, please contact: GoChapaa Support Engineer
            80 Robinson Road #02-00 Singapore 068898 Singapore Singapore
            support@gochapaa.com
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 12.THE LISENCE </Box>
          <Typography>
            {" "}
            Is valid until terminated by Sukuma Ventures PTE, LTD or by You.
            Your rights under this license will terminate automatically and
            without notice from Sukuma Ventures PTE, LTD if You fail to adhere
            to any of the terms and conditions of this license. Upon the
            termination, You shall stop all use of the Application, and destroy
            all copies, full or partial, of the Application.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}>
            {" "}
            13. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY{" "}
          </Box>
          <Typography>
            {" "}
            Sukuma Ventures PTE, LTD warrants that Sukuma Ventures PTE, LTD will
            comply with applicable third-party terms of agreement when using
            licensed Application. In accordance with Section 9 of the
            "Instructions for Minimum Terms of Developers End-User License
            Agreement, Apple and Google subsidiaries shall be third-party
            beneficiaries of this End User License Agreement and - upon Your
            acceptance.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 14. INTELLECTUAL PROPERTY RIGHTS </Box>
          <Typography>
            Sukuma Ventures PTE, LTD and the End-User acknowledge that, in the
            event of any third-party claim that the licensed Application or the
            End-Users possession and use of that licensed Application infringes
            on the third party's intellectual property rights, Sukuma Ventures
            PTE, LTD, and not Apple or Google, will be solely responsible for
            the investigation, defense, settlement and discharge or any such
            intellectual property infringement claims.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 15. APPLICABLE LAW </Box>
          <Typography>
            {" "}
            This license agreement is governed by the laws of Singapore
            excluding its conflicts of law rules.
          </Typography>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <Box sx={{ fontWeight: 800 }}> 16. MISCELLANEOUS </Box>
          <Typography>
            16.1 If any of the terms of this agreement should be or become
            invalid, the validity of the remaining provisions shall not be
            affected. Invalid terms will be replaced by valid ones formulated in
            a way that will achieve the primary purpose.
          </Typography>
          <Typography>
            1E2 Collateral agreements, changes and amendments are only valid if
            laid down in writing. The preceding clause can only be waived in
            writing.
          </Typography>
        </Typography>{" "}
      </Card>
    </Paper>
  );
};

export default TermsOfUse;
