import React from "react";
import {
  Box,
  Typography,
  Link,
  useTheme,
  ThemeProvider,
  Divider
} from "@mui/material";

const Footer = () => {
  const theme = useTheme();

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <ThemeProvider theme={theme}>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
          borderTop: `1px solid ${theme.palette.divider}`
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: theme.typography.responsiveFontSizes }}
        >
          Copyright © {getYear()} . All Rights Reserved By{" "}
          <Link href="#" color="inherit" underline="hover">
            Gochapaa
          </Link>
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            fontSize: theme.typography.responsiveFontSizes
          }}
        >
          <Link href="/privacyPolicy" color="inherit" underline="hover">
            Privacy & Policy
          </Link>
          <Link href="/termsOfUse" color="inherit" underline="hover">
            Terms & Conditions
          </Link>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
