import React, { useState } from "react";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  Tab,
  Grid
} from "@mui/material";
import Transactions from "./Transactions";
import WithdrawalRequests from "./WithdrawalRequests";

export default function TransactionPage() {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <Grid container>
          <Grid item xs={6}>
            <Tab
              label="Transactions"
              onClick={() => handleChange(null, 0)}
              sx={{
                color: theme.palette.text.primary,
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                padding: "16px",
                borderBottom: value === 0 ? `2px solid ${theme.palette.primary.main}` : "none"
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Tab
              label="Withdrawal Requests"
              onClick={() => handleChange(null, 1)}
              sx={{
                color: theme.palette.text.primary,
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                padding: "16px",
                borderBottom: value === 1 ? `2px solid ${theme.palette.primary.main}` : "none"
              }}
            />
          </Grid>
        </Grid>
      </Card>
      <Box
        flex="1"
        overflow="auto"
        sx={{
          color: theme.palette.text.default,
          fontWeight: "bold",
          marginTop: 2
        }}
      >
        {value === 0 && (
          <Transactions
            sx={{
              color: theme.palette.text.default,
              fontWeight: "bold",
              width: "100%"
            }}
          />
        )}
        {value === 1 && (
          <WithdrawalRequests
            sx={{
              color: theme.palette.text.default,
              fontWeight: "bold",
              width: "100%"
            }}
          />
        )}
      </Box>
    </ThemeProvider>
  );
}
