import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Grid,
  Typography
} from "@mui/material";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../Auth/AuthContext";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";
import "./UserWidget.scss"; 

const UserWidget = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [title] = useState("TOTAL USERS");
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(null);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await apiServiceFetchers.getTotalNumberOfUsers(
          currentUser.token
        );

        setTotalUsers(response.totalUsers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchTotalUsers();
  }, [currentUser.token, totalUsers]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme} sx={{ display: "flex", overflowX: "hidden" }}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.text.default,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
        }}
      >
        <Grid container direction="row" spacing={8}>
          {/* First Stack */}
          <Grid item sx={{ height: "100%" }}>
            <Stack direction="row" margin={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 56,
                  height: 56,
                  borderRadius: "20%",
                  backgroundColor: theme.palette.background.paper1,
                  color: theme.palette.text.primary,
                  border: "1px solid",
                  borderColor: theme.palette.background.paper1,
                  boxShadow: theme.shadows[2]
                }}
              >
                <GroupOutlinedIcon fontSize="large" />
              </Box>
            </Stack>
          </Grid>

          {/* Second Stack */}
          <Grid item sx={{ height: "100%" }}>
            <Stack direction="column" spacing={1} marginBottom={3}>
              <Box display="flex" alignItems="center">
                <Typography
                  className="title"
                  sx={{ fontSize: { xs: 38, md: 20, lg: 16 } }}
                >
                  {title}:
                </Typography>
                <Box className="totalUsers">
                  <Typography sx={{ fontSize: { xs: 38, md: 20, lg: 16 } }}>
                    {totalUsers}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="text"
                href="/users"
                sx={{
                  fontSize: { xs: 38, md: 20, lg: 16 },
                  color: theme.palette.text.primary,
                  whiteSpace: "nowrap",
                  border: "1px solid",
                  borderRadius: 2,
                  borderColor: theme.palette.background.paper1,
                  "&:hover": {
                    transform: "scale(0.95)"
                  }
                }}
              >
                view all {title.toLowerCase()}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default UserWidget;
