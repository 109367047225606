import { createTheme } from "@mui/material";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          background: {
            default: "#E8E9EB",
            paper: "#f5f5f5",
            paper1: "#18c687",
            paper2: "#00874b"
          },
          divider: "#bcc1d7",
          text: {
            primary: "#081549",
            secondary: "#808080"
          },
          alert: {
            warning: "#f91c6c",
            success: "#081549",
            disabled: "#808080"
          },
          button: {
            primary: "#fff",
            secondary: "#e4e6ee"
          }
        }
      : {
          background: {
            default: "#081549",
            paper: "#14245f",
            paper1: "#6873a1",
            paper2: "#081549"
          },
          divider: "#2a3d7e",
          text: {
            primary: "#f6f7f9",
            secondary: "#e4e6ee"
          },
          alert: {
            warning: "#f91c6c",
            success:"#081549",
            disabled: "#808080"
          },
          button: {
            primary: "#4a578f",
            secondary: "#59d19c",
            danger: "#E83902"
          }
        })
  },
  typography: {
    fontSize: 16, // Base font size
    h1: {
      fontSize: '0.7rem', // Example for heading
    },
    h2: {
      fontSize: '0.9rem', // Example for heading
    },
    body1: {
      fontSize: '0.8rem', // Default body text size
    },
    body2: {
      fontSize: '0.7rem', // Smaller body text
    },
    // Add responsive font sizes
    responsiveFontSizes: {
      xs: {
        fontSize: '0.875rem', // 14px for extra small screens
      },
      sm: {
        fontSize: '0.875rem', // 14px for small screens
      },
      md: {
        fontSize: '0.7rem', // 16px for medium screens
      },
      lg: {
        fontSize: '0.8rem', // 18px for large screens
      },
    },
  },
});

export const createAppTheme = (mode) => createTheme(getDesignTokens(mode));
