import React from "react";
import Datatable from "../../components/datatable/Datatable";
import { Box, Card, Typography } from "@mui/material";

const List = () => {
  return (
    <Card>
      <Typography
        sx={{
          padding: 1,
          fontWeight: "bold",
          fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" }
        }}
        gutterBottom
      >
        Users
      </Typography>
      <Box>
        <Datatable />
      </Box>
    </Card>
  );
};

export default List;
