import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Card,
  Typography,
  Box,
  Paper,
  Button,
  Snackbar
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const SkeletonShowBalanceCards = ({ theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={3}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Box>
                <Typography variant="h6">
                  <Skeleton width="80%" />
                </Typography>
                <Typography variant="h5" sx={{ marginTop: 1 }}>
                  <Skeleton width="60%" />
                </Typography>
              </Box>
              <Skeleton variant="circular" width={32} height={32} />
            </Card>
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
};

export const SkeletonShowTotalsCards = ({ theme }) => {
  return (
    <ThemeProvider theme={theme} sx={{ display: "flex", overflowX: "hidden" }}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.background.paper1,
          borderTopRightRadius: 40,
          borderBottomRightRadius: 40
        }}
      >
        <Grid container direction="row" spacing={8}>
          {/* First Stack */}
          <Grid item>
            <Stack direction="row" margin={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 56,
                  height: 56,
                  borderRadius: "20%",
                  backgroundColor: theme.palette.background.paper1,
                  color: theme.palette.text.primary,
                  border: "1px solid",
                  borderColor: theme.palette.background.paper1,
                  boxShadow: theme.shadows[2]
                }}
              >
                {/* Skeleton for the icon */}
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  animation="wave"
                />
              </Box>
            </Stack>
          </Grid>

          {/* Second Stack */}
          <Grid item>
            <Stack direction="column" spacing={1} marginBottom={3}>
              <Box display="flex" alignItems="center">
                {/* Skeleton for the title */}
                <Skeleton
                  variant="text"
                  width={100}
                  height={24}
                  animation="wave"
                />
                <Box sx={{ marginLeft: 1 }}>
                  {/* Skeleton for the total users */}
                  <Skeleton
                    variant="text"
                    width={40}
                    height={24}
                    animation="wave"
                  />
                </Box>
              </Box>
              {/* Skeleton for the button */}
              <Skeleton
                variant="rectangular"
                width={200}
                height={36}
                animation="wave"
              />
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export const SkeletonShowCircleChartsCards = ({ theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          borderRadius: "12px",
          padding: "16px",
          boxShadow: theme.shadows[1],
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {/* Title skeleton */}
          <Skeleton
            variant="text"
            width={200}
            height={24}
            animation="wave"
            sx={{ margin: "0 auto" }}
          />

          {/* Chart and Summary Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
              alignItems: "center",
            }}
          >
            {/* Skeleton for the chart */}
            <Skeleton
              variant="circular"
              width={200}
              height={200}
              animation="wave"
              sx={{ margin: "0 auto" }}
            />

            {/* Skeleton for the summary */}
            <Box sx={{ width: "50%" }}>
              {/* Title skeleton */}
              <Skeleton variant="text" width={180} height={20} animation="wave" />
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {/* KES skeleton */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width={80} height={20} animation="wave" />
                  <Skeleton variant="text" width={120} height={20} animation="wave" />
                </Box>
                {/* ETH skeleton */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width={80} height={20} animation="wave" />
                  <Skeleton variant="text" width={120} height={20} animation="wave" />
                </Box>
                {/* BTC skeleton */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width={80} height={20} animation="wave" />
                  <Skeleton variant="text" width={120} height={20} animation="wave" />
                </Box>
                {/* USDT skeleton */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width={80} height={20} animation="wave" />
                  <Skeleton variant="text" width={120} height={20} animation="wave" />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export const SkeletonShowGraphChartsCards = ({ theme }) => {
  return (
    <Box
      className="chart"
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: "12px",
        padding: "16px",
        boxShadow: theme.shadows[1]
      }}
    >
      {/* Skeleton for the title */}
      <Skeleton variant="text" width={100} height={24} animation="wave" />
      <Typography variant="body1" color="textSecondary" align="center">
        Loading...
      </Typography>
      {/* Skeleton for the button group */}
      <Skeleton
        variant="rectangular"
        width={200}
        height={36}
        animation="wave"
      />
      {/* Skeleton for the chart */}
      <Skeleton
        variant="rectangular"
        width="100%"
        height={300}
        animation="wave"
      />
    </Box>
  );
};

export const SkeletonShowTableCards = ({ theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3} style={{ padding: "20px" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ padding: "10px" }}
        >
          <Grid item>
            <Typography variant="body1" color="textSecondary" align="center">
              Loading...
            </Typography>
            <Box style={{ overflowX: "auto" }}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <Skeleton width={150} animation="wave" />
                    </th>
                    <th>
                      <Skeleton width={150} animation="wave" />
                    </th>
                    <th>
                      <Skeleton width={150} animation="wave" />
                    </th>
                    <th>
                      <Skeleton width={150} animation="wave" />
                    </th>
                    <th>
                      <Skeleton width={150} animation="wave" />
                    </th>
                    <th>
                      <Skeleton width={150} animation="wave" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[...Array(10)].map((_, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton width={150} animation="wave" />
                      </td>
                      <td>
                        <Skeleton width={150} animation="wave" />
                      </td>
                      <td>
                        <Skeleton width={150} animation="wave" />
                      </td>
                      <td>
                        <Skeleton width={150} animation="wave" />
                      </td>
                      <td>
                        <Skeleton width={150} animation="wave" />
                      </td>
                      <td>
                        <Skeleton width={150} animation="wave" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
            <Button
              variant="outlined"
              sx={{
                border: "2px solid",
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.default,
                borderColor: theme.palette.alert.disabled
              }}
              disabled // Disable the button during loading
            >
              Load More
            </Button>
          </Grid>
        </Grid>
        <Snackbar
          open={false} // Set to false by default for skeleton
          autoHideDuration={6000}
          onClose={() => {}} // Empty function for skeleton
          message={""} // Empty string for skeleton
        />
      </Paper>
    </ThemeProvider>
  );
};
