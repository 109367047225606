import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Button,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import { AuthContext } from "../../Auth/AuthContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ThemeProvider } from "@emotion/react";

function ChangePassword() {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConformPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setOpenSnackbar(false);

    if (newPassword !== confirmPassword) {
      setError("New password and confirmation do not match");
      setOpenSnackbar(true);
      return;
    }

    const updatedFields = {
      current: currentPassword,
      password: newPassword,
    };

    try {
      await apiServiceUpdate.updatePassword(updatedFields, currentUser.token);
      setSuccess("Password updated successfully");
      setOpenSnackbar(true);
    } catch (error) {
      setError("Failed to update password");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleToggleCurrentPasswordVisibility = () => {
    setCurrentShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConformPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        p={3}
        component="form"
        onSubmit={handleSubmit}
        background={theme.palette.background.default}
        borderColor={theme.palette.background.paper1}
        color={theme.palette.text.primary}
      >
        <TextField
          label="Current Password"
          type={showCurrentPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleCurrentPasswordVisibility}>
                  {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="New Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" ,color:"green"}}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={error ? "error" : "success"}
            variant="filled"
            sx={{
              width: "100%",
              backgroundColor: error ? "red" : "green",
            }}
          >
            {error || success}
          </MuiAlert>
        </Snackbar>

        <Button
          type="submit"
          variant="outlined"
          fullWidth
          sx={{
            width: "100%",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.button.primary,
            fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" },
          }}
        >
          Update Password
        </Button>
      </Box>
    </ThemeProvider>
  );
}

export default ChangePassword;
