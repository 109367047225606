import React, { useState, useEffect, useContext } from "react";
import {
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Typography,
  Grid,
  Button
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";

const RevenueWidget = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [title] = useState("TOTAL REVENUE");
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await apiServiceFetchers.getTotalNumberOfRevenue(
          currentUser.token
        );

        if (totalRevenue !== null) {
          setTotalRevenue(totalRevenue);
        }

        setTotalRevenue(response.totalChargeSum);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchTotalUsers();
  }, [currentUser.token, totalRevenue]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme} sx={{ display: "flex", overflowX: "hidden" }}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.text.default,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
        }}
      >
        <Grid container direction="row" spacing={5}>
          {/* First Stack */}
          <Grid item>
            <Stack direction="row" margin={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 56,
                  height: 56,
                  borderRadius: "20%",
                  backgroundColor: theme.palette.background.paper1,
                  color: theme.palette.text.primary,
                  border: "1px solid",
                  borderColor: theme.palette.background.paper1,
                  boxShadow: theme.shadows[2]
                }}
              >
                <PaymentsOutlinedIcon fontSize="large" />
              </Box>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="column" spacing={1} marginBottom={3}>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: { xs: 38, md: 20, lg: 16 } }}>
                {title}:
                </Typography>
                <Box sx={{ marginLeft: 1 }}>
                  <Typography sx={{ fontSize: { xs: 38, md: 20, lg: 16 } }}>
                  {(totalRevenue.toFixed(2))} USD               
                 </Typography>
                </Box>
              </Box>
              <Button
                variant="text"
                href="/revenue"
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: { xs: 38, md: 20, lg: 16 },
                  whiteSpace: "nowrap",
                  border: "1px solid",
                  borderRadius: 2,
                  borderColor: theme.palette.background.paper1,
                  "&:hover": {
                    transform: "scale(0.95)"
                  }
                }}
              >
                View all Revenue
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default RevenueWidget;