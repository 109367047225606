import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_GOCHAPAA_API_ENDPOINT;

const apiService = {
  deleteUser: async (id, token) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/auth/me/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
  deleteFaqs: async (id, token) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/v1/faqs/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

};

export default apiService;
