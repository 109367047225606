import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Grid,
  Typography
} from "@mui/material";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../Auth/AuthContext";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";

const AssetsWidget = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [title] = useState("TOTAL ASSETS");
  const [totalCoins, setTotalcoins] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await apiServiceFetchers.getTotalNumberOfCoins(
          currentUser.token
        );

        if (totalCoins !== null) {
          setTotalcoins(totalCoins);
        }

        setTotalcoins(response.totalCoins);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchTotalUsers();
  }, [currentUser.token, totalCoins]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.alert.warning,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10
        }}
      >
        <Grid container direction="row" spacing={8}>
          <Grid item>
            <Stack direction="row" margin={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 56,
                  height: 56,
                  borderRadius: "20%",
                  backgroundColor: theme.palette.background.paper1,
                  color: theme.palette.text.primary,
                  border: "1px solid",
                  borderColor: theme.palette.background.paper1,
                  boxShadow: theme.shadows[2]
                }}
              >
                <CategoryOutlinedIcon fontSize="large" />
              </Box>
            </Stack>
          </Grid>

          <Grid item>
            <Stack direction="column" spacing={1} marginBottom={3}>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: { xs: 38, md: 20, lg: 16 } }}>
                  {title}:
                </Typography>
                <Box
                  sx={{ marginLeft: 1, fontSize: { xs: 38, md: 20, lg: 16 } }}
                >
                  <Typography>{totalCoins}</Typography>
                </Box>
              </Box>
              <Button
                variant="text"
                href="/coins"
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: { xs: 38, md: 20, lg: 16 },
                  whiteSpace: "nowrap",
                  border: "1px solid",
                  borderRadius: 2,
                  borderColor: theme.palette.background.paper1,
                  "&:hover": {
                    transform: "scale(0.95)"
                  }
                }}
              >
                view all {title.toLowerCase()}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default AssetsWidget;
